import { Component, Input, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { PropertyFeature } from "src/app/infrastructure/model/propertyFeature";
import { CommonModule, NgFor, NgIf } from "@angular/common";

@Component({
    selector: 'property-features',
    templateUrl: 'property-features.component.html',
    styleUrls: ['property-features.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgIf]
})

export class PropertyFeaturesComponent implements OnInit {
    @Input() features: Array<PropertyFeature>;
    @Input() showDescription: boolean;

    constructor() { }

    ngOnInit() { }
}


