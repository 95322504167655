<div class="property-features">
    <ul>
        <li class="feature" *ngFor="let feature of features" title="{{feature.description}}">
            <div>
                <label *ngIf="feature.badgeCount && feature.badgeCount>0 && !showDescription" title="{{feature.description}}">{{feature.badgeCount}}</label>
                <label *ngIf="!(feature.badgeCount && feature.badgeCount>0 && !showDescription)"></label>
                <img [src]="feature.iconUrl" title="{{feature.description}}" width="24px" height="24px"/>
                <span *ngIf="!showDescription" class="tooltip-description" title="{{feature.description}}">{{feature.title}}</span>
                <span *ngIf="showDescription" class="description"> {{feature.description}}</span>
            </div>
        </li>
    </ul>
</div>
